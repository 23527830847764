import React, { useState } from 'react';
import { createProfile } from './services/apiService';
import { WebSocketProvider } from './services/WebSocketContext';
import AccountForm from './components/quiz/AccountForm';
import Quiz from './components/quiz/Quiz';

export default function RpgQuiz() {
    const [userId, setUserId] = useState(null);

    const handleCreateAccount = async (profileData) => {
        const uuid = await createProfile(profileData);
        setUserId(uuid);
    };

    const resetUser = () => {
        setUserId(null); // Reset userId to null, showing the AccountForm again
    };

    return (
        <WebSocketProvider >
        <div>
            {userId ? (
                    <Quiz userId={userId} onResetUser={resetUser} />
            ) : (
                <AccountForm onCreateAccount={handleCreateAccount} />
            )}
        </div>
        </WebSocketProvider >
    );
}