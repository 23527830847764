'use client'
import { useState } from 'react';
import axios from "axios";
import './App.css';
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'

import RpgQuiz from "./RpgQuiz";



// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ')
// }

var postData = {
    "email": "test@example.com",
    "name": "Test User",
    "company": "Test Company"
}


function App() {
  const [text, setText] = useState('')
  const apiUrl = process.env.REACT_APP_API_ADDRESS;
  const handleSubmit = async (e) => {
    e.preventDefault()

      const result = await axios.post(`${apiUrl}/create_profile/`)
    console.log(result.data)
  }
return (
    <>

        <html class="h-screen bg-zinc-950">
        <body class="h-3/6 bg-zinc-600 ">

      <div className="min-h-full">
        <div className="bg-zinc-800 pb-32">
          <Disclosure as="nav" className="bg-zinc-950">
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="border-b border-gray-950">
                <div className="flex h-16 items-center justify-between px-4 sm:px-0">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                          alt="Pixelegion"
                          src="./logo.svg"
                          className="h-8 w-8"
                      />
                    </div>
                    <div className="hidden md:block">
                      <h1 className="text-3xl font-bold tracking-tight text-white pl-2">PIXELEGION</h1>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">


                    </div>
                  </div>

                </div>
              </div>
            </div>


          </Disclosure>
          <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-white">Tech Entrepreneur RPG Character Builder Quiz</h1>
            </div>
          </header>
        </div>

        <main className="-mt-32">
          <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
              <RpgQuiz />
            </div>
          </div>
        </main>
      </div>
        </body>
        </html>
    </>
)


}

export default App;
