import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import io from 'socket.io-client';
import _ from 'lodash'; // Import lodash for throttling

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
    const [progress, setProgress] = useState(0);
    const [stepProgress, setStepProgress] = useState(0);
    const [result, setResult] = useState(null);
    const [resultImage, setResultImage] = useState(null);
    const [showProgress, setShowProgress] = useState(false);

    const apiUrl = process.env.REACT_APP_API_ADDRESS;

    // WebSocket instance (using ref to avoid re-initialization on re-renders)
    const socketRef = useRef(
        io(apiUrl, {
            transports: ['websocket'],
            pingInterval: 25000,
            pingTimeout: 120000,
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
        })
    );

    const socket = socketRef.current;

    const resetWebSocketState = (clearResult = true) => {
        setProgress(0); // Reset progress
        setStepProgress(0); // Reset step progress
        if (clearResult) {
            setResult(null); // Clear result
            setResultImage(null); // Clear result image
        }
        setShowProgress(false); // Hide progress bar
    };

    useEffect(() => {
        // Event handlers
        const handleProgressUpdate = _.throttle((data) => {
            const taskPercentage = Math.round((data.completed_tasks / data.total_tasks) * 100);
            const stepPercentage = Math.round((data.current_step / data.total_steps) * 100);
            setProgress(taskPercentage);
            setStepProgress(stepPercentage);
        }, 500); // Throttle updates to once every 500ms

        const handleResultComplete = (data) => {
            console.log('Result Complete:', data);
            setResult(data.result);
            setResultImage(data.image);
            setShowProgress(false);
            //resetWebSocketListeners(); // Remove listeners after the process completes
        };

        const resetWebSocketListeners = () => {
            socket.off('progress_update', handleProgressUpdate);
            socket.off('result_complete', handleResultComplete);
        };

        // Setup WebSocket listeners
        socket.on('connect', () => {
            console.log("Connected to WebSocket server");
        });

        socket.on('disconnect', () => {
            console.warn("Disconnected from WebSocket server");
        });

        socket.on('connect_error', (err) => {
            console.error("WebSocket connection error:", err);
        });

        socket.on('progress_update', handleProgressUpdate);
        socket.on('result_complete', handleResultComplete);

        // Cleanup listeners on component unmount
        return () => {
            resetWebSocketListeners();
            socket.disconnect(); // Ensure WebSocket connection is closed
        };
    }, [socket]);

    // Reconnection handler
    useEffect(() => {
        socket.on('disconnect', () => {
            console.warn('Socket disconnected. Attempting to reconnect...');
            socket.connect(); // Attempt to reconnect
        });

        socket.on('reconnect', () => {
            console.info('Socket reconnected successfully.');
        });

        return () => {
            socket.off('disconnect');
            socket.off('reconnect');
        };
    }, [socket]);

    return (
        <WebSocketContext.Provider value={{
            progress,
            stepProgress,
            result,
            resultImage,
            showProgress,
            setShowProgress,
            resetWebSocketState,
        }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocket = () => useContext(WebSocketContext);
