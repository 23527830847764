const apiUrl = process.env.REACT_APP_API_ADDRESS;

export async function createProfile(profileData) {
    const response = await fetch(`${apiUrl}/create_profile`, {
        method: "POST",
        body: JSON.stringify(profileData),
        headers:{'Content-Type':'application/json'},
    });
    const json = await response.json();
    return json.result.uuid;
}

export async function createQuiz(profileData) {
    const response = await fetch(`${apiUrl}/quiz/create_quiz`, {
        method: "POST",
        body: JSON.stringify(profileData),
        headers: { 'Content-Type': 'application/json' },
    });
    const json = await response.json();
    return json.result.quiz_uuid;
}

export async function submitUserPhoto(capturedImage, quizUuid) {
    try {
        // Convert captured image to a Blob
        const response = await fetch(capturedImage);
        const blob = await response.blob();

        // Prepare form data
        const formData = new FormData();
        formData.append("image", blob, "user_image.png");
        formData.append("quiz_uuid", quizUuid);

        // Submit form data to API
        const result = await fetch(`${apiUrl}/quiz/input_image`, {
            method: "POST",
            body: formData,
        });

        return await result.json(); // Return response if needed
    } catch (error) {
        console.error("Failed to submit photo:", error);
        throw error;
    }
}

export async function getQuestion(quiz_uuid) {
    const questionData = { quiz_uuid };
    const response = await fetch(`${apiUrl}/quiz/question`, {
        method: "POST",
        body: JSON.stringify(questionData),
        headers: { 'Content-Type': 'application/json' },
    });
    return await response.json();
}

export async function sendSelectedAnswer(answerData){
    const response = await fetch(`${apiUrl}/quiz/answer`, {
        method: "POST",
        body: JSON.stringify(answerData),
        headers: {'Content-Type': 'application/json'},
    });
    return await response.json();
}
