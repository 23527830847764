import React, { useState, useEffect } from 'react';
import { createQuiz, getQuestion, sendSelectedAnswer } from '../../services/apiService';
import Question from './Question';
import PhotoCapture from './PhotoCapture';
import { useWebSocket } from '../../services/WebSocketContext';
import ProgressBar from './ProgressBar';

export default function Quiz({ userId, onResetUser }) {
    const [quizUuid, setQuizUuid] = useState(null);
    const [questionData, setQuestionData] = useState({ question: '', options: [] });
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [questionIndex, setQuestionIndex] = useState(0);
    const [isAnswerSubmitted, setIsAnswerSubmitted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [photoCaptured, setPhotoCaptured] = useState(false);
    const { progress, result, resultImage, showProgress, setShowProgress, resetWebSocketState } = useWebSocket();

    const initializeQuiz = async () => {
        setLoading(true);
        setQuizUuid(null);
        setQuestionData({ question: '', options: [] });
        setSelectedAnswer(null);
        setQuestionIndex(0);
        setIsAnswerSubmitted(false);
        resetWebSocketState(true);
        setShowProgress(false);
        setPhotoCaptured(false);

        const quizId = await createQuiz({ user_uuid: userId, theme: 'default' });
        setQuizUuid(quizId);
        setLoading(false);
    };

    useEffect(() => {
        initializeQuiz();
    }, [userId]);

    const loadNextQuestion = async () => {
        setLoading(true);
        const { question, answer_a, answer_b, answer_c, answer_d } = await getQuestion(quizUuid);
        setQuestionData({ question, options: [answer_a, answer_b, answer_c, answer_d] });
        setIsAnswerSubmitted(false);
        setSelectedAnswer(null);
        setLoading(false);
    };

    useEffect(() => {
        if (quizUuid && questionIndex < 5) {
            loadNextQuestion();
        } else if (quizUuid && questionIndex === 5) {
            fetchResult();
        }
    }, [quizUuid, questionIndex]);

    useEffect(() => {
        if (result) {
            setShowProgress(false); // Ensure progress bar hides when result is ready
        }
    }, [result]);

    const handleAnswerSelection = (answer) => {
        setSelectedAnswer(answer);
    };

    const handleSubmitAnswer = async () => {
        if (selectedAnswer) {
            console.log("User selected:", selectedAnswer);
            setIsAnswerSubmitted(true);
            await sendSelectedAnswer({ quiz_uuid: quizUuid, answer: selectedAnswer });
            setQuestionIndex((prevIndex) => prevIndex + 1);
        }
    };

    const fetchResult = async () => {
        setShowProgress(true);
        const apiUrl = process.env.REACT_APP_API_ADDRESS;

        try {
            const response = await fetch(`${apiUrl}/quiz/generate_result`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ quiz_uuid: quizUuid, user_uuid: userId }),
            });

            if (!response.ok) {
                throw new Error("Failed to generate result.");
            }
        } catch (error) {
            console.error("Error generating result:", error);
            setShowProgress(false);
        }
    };

    return (
        <div>
            {loading ? (
                <div className="flex items-center justify-center h-64">
                    <p className="text-lg font-semibold text-gray-700">Loading...</p>
                </div>
            ) : !photoCaptured ? (
                <PhotoCapture quizUuid={quizUuid} onSubmitPhoto={() => setPhotoCaptured(true)} />
            ) : showProgress ? (
                <ProgressBar />
            ) : result ? (
                <div className="flex flex-col space-y-8">
                    <div className="result p-4">
                        <h2 className="text-2xl font-bold mb-4">Your Tech Entrepreneur RPG Character</h2>
                        {resultImage && (
                            <img src={resultImage} alt="Quiz Result" className="mb-4 rounded-lg shadow-md" />
                        )}
                        <p className="text-gray-800 whitespace-pre-line py-8">{result}</p>
                        <button
                            onClick={() => {
                                initializeQuiz();
                                onResetUser();
                            }}
                            className="flex w-full justify-center rounded-md bg-blue-600 px-3 py-4 text-lg font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                        >
                            Restart
                        </button>
                    </div>
                </div>
            ) : (
                <div className="flex flex-col space-y-8">
                    <Question
                        question={questionData.question}
                        options={questionData.options}
                        onAnswer={handleAnswerSelection}
                        selectedAnswer={selectedAnswer}
                    />
                    <button
                        onClick={handleSubmitAnswer}
                        disabled={!selectedAnswer || isAnswerSubmitted}
                        className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-4 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Submit Answer
                    </button>
                </div>
            )}
        </div>
    );
}
